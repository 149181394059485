import { Collapse } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export default function CollapseBox({ title, note, isExpanded, setIsExpanded }: any) {
    const styles = {
        whyBox: {
            minHeight: '20vh',
            width: '100%',
            marginBottom: '20px'
        },
        reasonBox: {
            backgroundColor: 'white',
            padding: '10px 40px',
            marginTop: '20px',
            borderRadius: '5px',
            width: 'fit-content',
            maxWidth: '500px'
        }
    }

    return (
        <div onClick={() => setIsExpanded(!isExpanded)} style={styles.whyBox}>
            <div className='whyBox flexRow'>
                <span>{title}</span>
                <span className="flexColumnCenter"><KeyboardDoubleArrowRightIcon /></span>
            </div>

            <Collapse in={isExpanded} mountOnEnter unmountOnExit timeout={1000}>
                <div className="flexRowVCenter">
                    <div style={styles.reasonBox}>{note}</div>
                </div>
            </Collapse>
        </div>
    )
}