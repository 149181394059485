import { Grid } from "@mui/material";
import InquireGrid from "./InquireGrid";
import SearchIcon from '@mui/icons-material/Search';

export default function ContactUsView() {
    const styles = {
        padd: {
            padding: '20px'
        },
        image: {
            marginLeft: '30px',
            fontSize: '60px'
        }
    }

    return (
        <article>
            <img className="fullPageImage" src='./contactUs.jpg' />
            <div className="flexRow">
                <article className='contactArticle' style={styles.padd}>
                    <Grid container rowSpacing={2} className="flexRow">
                        <Grid item xs={12} className="flexRow">
                            <title>Contact Us<SearchIcon style={styles.image}/></title>
                        </Grid>
                        <Grid item xs={12}>
                            <InquireGrid />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>{process.env.REACT_APP_ADDRESS_LINE_1}</Grid>
                        <Grid item xs={12}>{process.env.REACT_APP_ADDRESS_LINE_2}</Grid>
                        <Grid item xs={12}>{process.env.REACT_APP_ADDRESS_LINE_3}</Grid>
                        <Grid item xs={12}><a href={"mailto:" + process.env.REACT_APP_EMAIL}>{process.env.REACT_APP_EMAIL}</a></Grid>
                    </Grid>
                </article>
            </div>
        </article>
    )
}