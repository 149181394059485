import SlideImage from "../SlideImage";
import Release_1_0 from "./Release_1_0";
import Release_1_1 from "./Release_1_1";
import Release_1_2 from "./Release_1_2";

export default function ReleaseNotes() {

    return (
        <article>
            <SlideImage />
            <div className="flexRow">
                <Release_1_2 />
                <Release_1_1 />
                <Release_1_0 />
            </div>
        </article>
    )
}