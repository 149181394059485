import { Grid } from "@mui/material"

export default function Release_1_1({ }: any) {
    const styles = {
        padd: {
            padding: '20px'
        },
        image: {
            width: '100px'
        }
    }

    return (
        <article className='contactArticle' style={styles.padd}>
            <Grid container rowSpacing={2} className="flexRow">
                <Grid item xs={12} className="flexRow">
                    <title>Release 3/6/2023</title>
                    <img src={''} style={styles.image} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}><b>Admin</b></Grid>
                        <Grid item xs={1}></Grid><Grid item xs={11}>Statistic Dashboard</Grid>
                        <Grid item xs={2}></Grid><Grid item xs={10}>Offers updated campsite statistics, including insights on the most booked or canceled sites.</Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}><b>Guest</b></Grid>
                        <Grid item xs={1}></Grid><Grid item xs={11}>Interactive site map</Grid>
                        <Grid item xs={2}></Grid><Grid item xs={10}>Empowers guests to filter sites and view results on an interactive campground map.</Grid>
                        <Grid item xs={1}></Grid><Grid item xs={11}>Email Confirmation</Grid>
                        <Grid item xs={2}></Grid><Grid item xs={10}>Provides guests with a detailed record of their booking through an email confirmation containing all reservation information.</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </article>
    )
}