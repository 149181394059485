import { Grid } from "@mui/material"

export default function Release_1_1({ }: any) {
    const styles = {
        padd: {
            padding: '20px'
        },
        image: {
            width: '100px'
        }
    }

    return (
        <article className='contactArticle' style={styles.padd}>
            <Grid container rowSpacing={2} className="flexRow">
                <Grid item xs={12} className="flexRow">
                    <title>Release 4/3/2024</title>
                    <img src={''} style={styles.image} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}><b>Admin</b></Grid>
                        <Grid item xs={1}></Grid><Grid item xs={11}>Vacancy Dashboard</Grid>
                        <Grid item xs={2}></Grid><Grid item xs={10}>Provides Hosts with a comprehensive view of campsite vacancies and their durations, facilitating efficient management.</Grid>
                        <Grid item xs={1}></Grid><Grid item xs={11}>Today's Activity Dashboard</Grid>
                        <Grid item xs={2}></Grid><Grid item xs={10}>Enables Hosts to monitor check-outs and check-ins for the day, ensuring timely preparation for incoming guests.</Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}><b>Guest</b></Grid>
                        <Grid item xs={1}></Grid><Grid item xs={11}>Guest waitlist</Grid>
                        <Grid item xs={2}></Grid><Grid item xs={10}>Allows guests to join a campsite watch list when fully booked, receiving alerts upon cancellations to prevent vacancies.</Grid>
                        <Grid item xs={1}></Grid><Grid item xs={11}>Multiple site checkout</Grid>
                        <Grid item xs={2}></Grid><Grid item xs={10}>Enhances guest experience by enabling bookings for multiple sites in a single transaction.</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </article>
    )
}