import { CircularProgress } from '@mui/material';

export default function Overlay() {
    const styles = {
        overlay: {
            position: 'fixed' as 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '2',
            cursor: 'pointer'
        },
        spinner: {
            position: 'fixed' as 'fixed',
            width: '100px',
            height: '100px',
            top: '49%',
            left: '49%',
        }
    };

    return (
        <div style={styles.overlay}><CircularProgress style={styles.spinner} /></div>
    );
}
