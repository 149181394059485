import { Button, Collapse, Grid } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import CollapseBox from "./CollapseBox";

export default function Product() {
    const styles = {
        product: {
            width: '100%',
            maxWidth: '300px'
        },
        button: {
            margin: '20px auto'
        },
        icon: {
            maxHeight: '300px'
        }
    }

    const [isExpanded, setIsExpanded] = React.useState(-1);

    return (
        <article className="flexColumnVCenter">
            <div style={styles.product}>
                <CollapseBox
                    title={'Why Us'}
                    note={'We are committed to optimizing operational efficiency for your business, allowing maximization of productivity and quality of life.'}
                    isExpanded={isExpanded === 0}
                    setIsExpanded={() => setIsExpanded(isExpanded === 0 ? -1 : 0)} />
                <CollapseBox
                    title={'New Features'}
                    note={
                        <div>
                            <Grid container rowSpacing={1}>
                                <Grid item xs={12}><b>Release: 4/3/2024</b></Grid>
                                <Grid item xs={12}><b>Admin</b></Grid>
                                <Grid item xs={12}>Vacancy Dashboad</Grid>
                                <Grid item xs={12}>Todays Activity Dashboad</Grid>
                                <Grid item xs={12}><b>Guest</b></Grid>
                                <Grid item xs={12}>Guest waitlist</Grid>
                                <Grid item xs={12}>Reservation reminder alerts</Grid>
                                <Grid item xs={12}>Multiple site checkout</Grid>
                                <Grid item xs={12}><Button onClick={() => window.location.href = '/ReleaseNotes'}>more details</Button></Grid>
                            </Grid>
                        </div>
                    }
                    isExpanded={isExpanded === 1}
                    setIsExpanded={() => setIsExpanded(isExpanded === 1 ? -1 : 1)} />
            </div>
        </article>
    )
}