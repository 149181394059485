import React from "react";
import { Grid } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export default function Article({ title, message, icon }: any) {
    const styles = {
        article: {
            //  minHeight: '15vh',
            padding: '20px',
        },
        pond: {
            display: 'flex',
            justifyContent: 'center'
        },
        icon: {
            height: '100%',
            width: '100%',
        }
    }


    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <div className='article' style={styles.article} onClick={() => setIsExpanded(!isExpanded)}>
            <Grid container rowSpacing={4}>
                <Grid item xs={10}>
                    <title>{title}</title>
                </Grid>
                <Grid item xs={2} className="flexRowStart">
                    {
                        isExpanded
                            ? <KeyboardDoubleArrowDownIcon className={'titleArrow dropDownIcon'} onClick={() => setIsExpanded(false)} />
                            : <KeyboardDoubleArrowUpIcon className={'titleArrow dropDownIcon'} onClick={() => setIsExpanded(true)} />
                    }
                </Grid>
                <Grid item xs={12}>
                    <div style={styles.pond}>
                        <img src={icon} style={styles.icon} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={isExpanded} mountOnEnter unmountOnExit>
                        {message}
                    </Collapse >
                </Grid>
            </Grid>
        </div>
    )
}