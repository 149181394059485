import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavigationBar from "./Navigation/NavBar";
import HomeView from "./Home/HomeView";
import './App.css';
import AboutView from "./About/AboutView";
import ContactUsView from "./ContactUs/ContactUsView";
import ReleaseNotes from "./ReleaseNotes/ReleaseNotesView";

export default function App() {
    return (
        <main>
            <NavigationBar />
            <Router>
                <Routes>
                    <Route path="/" element={<HomeView />}></Route>
                    <Route path="/About" element={<AboutView />}></Route>
                    <Route path="/ContactUs" element={<ContactUsView />}></Route>
                    <Route path="/ReleaseNotes" element={<ReleaseNotes />}></Route>
                </Routes>
            </Router>
        </main >
    )
}
