import { Button, Collapse } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';

export default function Product() {
    const styles = {
        product: {
            padding: '20px',
        },
        button: {
            margin: '20px auto'
        },
        icon: {
            maxHeight: '300px'
        }
    }

    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <article className='productArticle'>
            <div className="flexColumnCenter" onClick={() => setIsExpanded(!isExpanded)} style={styles.product}>
                <img src={'./logos/SmoreSites.svg'} style={styles.icon} />
                {
                    !isExpanded &&
                    <Button
                        style={styles.button}
                        variant="contained"
                        color={'warning'}
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={() => setIsExpanded(true)}>
                        learn more
                    </Button>
                }
                {
                    isExpanded &&
                    <Button
                        style={styles.button}
                        variant="contained"
                        color={'primary'}
                        endIcon={<SearchIcon />}
                        onClick={() => window.location.href = './ContactUs'}>
                        inquire
                    </Button>
                }
                <Collapse in={isExpanded} mountOnEnter unmountOnExit timeout={1000}>
                    S'MoreSites is a comprehensive web application designed
                    to streamline campground management operations effectively.
                    With S'MoreSites, you can easily monitor the status of your
                    campground, strategize for upcoming events, and provide
                    guests with a seamless reservation experience and access
                    to essential campground information. Enhance business
                    efficiency and promote work-life balance by integrating
                    S'MoreSites with your campground.
                </Collapse>
            </div>
        </article>
    )
}