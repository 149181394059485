import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from 'uuid';

interface INavigationBarParameters {
}

export default function NavigationBar({ }: INavigationBarParameters) {
    const styles = {
        banner: {
            background: 'linear-gradient(to right, white, rgb(4,60,119))',
            zIndex: 99
        },
        grid: {
            height: '100%'
        },
        image: {
            height: '30px',
            marginRight: '5px'
        },
        homeLink: {
            cursor: 'pointer'
        }
    };

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => { setAnchorElNav(event.currentTarget); };
    const handleCloseNavMenu = () => { setAnchorElNav(null); };

    const urlPieces: string[] = window.location.href.split('/');
    const action = '/' + urlPieces[urlPieces.length - 1].toLocaleLowerCase();

    const pages = [
        { label: "Home", link: '/' },
        { label: "Our Story", link: '/About' },
        { label: "Release Notes", link: '/ReleaseNotes' },
        { label: "Contact Us", link: '/ContactUs' }
    ];

    return (
        <AppBar position="sticky">
            <Container maxWidth={false} style={styles.banner}>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={uuidv4()} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                        component='a'
                                        href={page.link}
                                        sx={{
                                            mr: 2,
                                            display: { xs: "flex" },
                                            fontWeight: action === page.link.toLowerCase() ? 700 : 300,
                                            fontSize: action === page.link.toLowerCase() ? 'larger' : '',
                                            color: "inherit",
                                            textDecoration: "none",
                                        }}
                                    >
                                        {page.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex" } }} onClick={() => window.location.href = '/'} style={styles.homeLink}>
                        <img src={'/logos/Box_Creek_Logo.svg'} style={styles.image} />
                        <img src={'/logos/Box_Creek_Banner.svg'} style={styles.image} />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" }, marginRight: "10vw", justifyContent: 'space-around' }}>
                        {pages.map((page) => (
                            <Typography
                                key={uuidv4()}
                                variant="h6"
                                noWrap
                                component="a"
                                href={page.link}
                                sx={{
                                    mr: 2,
                                    display: { xs: "none", sm: "flex" },
                                    alignItems: { sm: "center" },
                                    fontWeight: action === page.link.toLowerCase() ? 700 : 300,
                                    fontSize: action === page.link.toLowerCase() ? 'larger' : '',
                                    letterSpacing: ".1rem",
                                    color: "inherit",
                                    textDecoration: "none",
                                }}
                            >
                                {page.label}
                            </Typography>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
}