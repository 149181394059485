import { Grid } from "@mui/material";
import Article from "./Article";
import SlideImage from "../SlideImage";

export default function AboutView() {
    const mission = 'We are dedicated to revolutionizing logistics ' +
        'for businesses by providing innovative ' +
        'solutions that enhance operational efficiency ' +
        'and elevate workplace satisfaction.'

    const commit = '  At our core, we are dedicated to alleviating the ' +
        'challenges that businesses face through the ' +
        'development of top-notch software solutions. Our ' +
        'passionate team values and actively seeks out ' +
        'customer feedback as a crucial element in our ' +
        'continuous efforts to refine existing features and ' +
        'innovate new ones tailored to meet your specific ' +
        'requirements. By fostering a collaborative ' +
        'environment that prioritizes open communication ' +
        'and adaptability, we strive to ensure that our ' +
        'software not only meets but exceeds your ' +
        'expectations, ultimately driving your business ' +
        'towards greater success and efficiency.'

    const drive = 'From a humble creek to a majestic ocean, ' +
        'the transformation is awe-inspiring. ' +
        'Starting as a mere stream, it gathers strength, ' +
        'evolving into a mighty river, ' +
        'and eventually expanding into a sprawling delta. ' +
        'At Box Creek LLC, we firmly believe that ideas, ' +
        'like water, should never be confined to a box. ' +
        'To truly flourish, they must be set free ' +
        'to grow into something greater.'

    return (
        <article className="aboutUsArticle">
            <SlideImage />
            <div className="flexRow">
                <article className="storyArticle">
                    <Grid container rowSpacing={4} columnSpacing={4}>
                        <Grid item xs={12} sm={6} md={4} className="flexRowCenter">
                            <Article title={'Our Mission'} message={mission} icon={'./ourStoryImages/mountain-stars.jpg'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="flexRowCenter">
                            <Article title={'Our Commitment'} message={commit} icon={'./ourStoryImages/target.jpg'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="flexRowCenter">
                            <Article title={'Our Drive'} message={drive} icon={'./ourStoryImages/hiking.jpg'} />
                        </Grid>
                    </Grid>
                </article>
            </div>
        </article>
    )
}