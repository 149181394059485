import { AES } from 'crypto-js'
import Utf8 from 'crypto-js/enc-utf8';

export function Decrypt(data: any): any {
    if (!data) {       
        return data;
    }

    if (process.env.REACT_APP_ENV === 'development') {
        if(typeof data === 'string'){
            // string when reading from cache
            // object when returning from api
            return JSON.parse(data);
        }

        return data;
    } 

    const passphrase = process.env.REACT_APP_ENCRYPT_KEY ?? '';
    const bytes = AES.decrypt(data, passphrase);
    const originalText = bytes.toString(Utf8);
    return originalText ? JSON.parse(originalText) : originalText;
}

export function Encrypt(data: any): any {
    if (process.env.REACT_APP_ENV === 'development') {
        return JSON.stringify(data);
    }

    const passphrase = process.env.REACT_APP_ENCRYPT_KEY ?? '';
    const text = JSON.stringify(data);
    const result = AES.encrypt(text, passphrase).toString();

    return result;
}