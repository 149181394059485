import { Grid } from "@mui/material"

export default function Release_1_1({ }: any) {
    const styles = {
        padd: {
            padding: '20px'
        },
        image: {
            width: '100px'
        }
    }

    return (
        <article className='contactArticle' style={styles.padd}>
            <Grid container rowSpacing={2} className="flexRow">
                <Grid item xs={12} className="flexRow">
                    <title>Release 12/6/2023</title>
                    <img src={''} style={styles.image} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={12}><b>Admin</b></Grid>
                            <Grid item xs={1}></Grid><Grid item xs={11}>Quick Search</Grid>
                            <Grid item xs={2}></Grid><Grid item xs={10}>Facilitates rapid retrieval of upcoming reservations using any piece of guest information.</Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}><b>Guest</b></Grid>
                            <Grid item xs={1}></Grid><Grid item xs={11}>Ease of checkout</Grid>
                            <Grid item xs={2}></Grid><Grid item xs={10}>Enhances booking convenience for guests by enabling Google autofill and authentication during the booking process.</Grid>
                            <Grid item xs={1}></Grid><Grid item xs={11}>Reservation reminder alerts</Grid>
                            <Grid item xs={2}></Grid><Grid item xs={10}>Sends guests timely reminders 7 days and 1 day before their reservation date to prevent missed bookings.</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </article>
    )
}