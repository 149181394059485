import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import React from "react";
import { detectRobot } from "../botDetect";
import getUserInformation from "../infoGather";
import { Decrypt, Encrypt } from "../encryption";
import Overlay from "../Overlay";

export interface IInquireRequest {
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    message: string,
    userAgent: string,
    info: object
}

export interface IInquireResponse {
    success: boolean,
    message: string
}

export default function InquireGrid() {
    const styles = {
        button: {
            fontSize: '20px'
        }
    }

    const isLocal = window.location.href.startsWith('http://localhost') || window.location.href.startsWith('192.168.1.');
    const BASE_API_URL = isLocal
        ? process.env.REACT_APP_BASE_API_URL_LOCAL
        : process.env.REACT_APP_BASE_API_URL_AZURE;

    const empty: IInquireRequest = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        userAgent: '',
        info: {}
    }

    const [isRobot, setIsRobot] = React.useState(true);
    const [result, setResult] = React.useState<IInquireResponse | undefined>();
    const [inquire, setInquire] = React.useState<IInquireRequest>(empty);
    const [inquirySent, setInquirySent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        let userAgent = '';
        try {
            userAgent = navigator.userAgent;
            setIsRobot(detectRobot(userAgent));
        } catch { }

        let info = {};
        try {
            info = getUserInformation();
        } catch { }

        setInquire({ ...inquire, info: info, userAgent: userAgent });
    }, [])

    async function contact() {
        setIsLoading(true);
        await fetch(BASE_API_URL + '/Inquire/Send',
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: Encrypt(inquire)
            })
            .then(response => response.json())
            .then(data => {
                const decrypted: IInquireResponse = Decrypt(data);
                setResult(decrypted);
                setInquire(empty);
                setInquirySent(true);
            })
            .catch((e: Error) => {
                console.error(e);
                setResult({
                    success: false,
                    message: 'We were unable to send your inquiry.  Please try again later or give us a call or email.'
                })
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            {
                isLoading &&
                <Overlay />
            }
            {
                !inquirySent &&
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label='First Name'
                            required
                            fullWidth
                            value={inquire.firstName}
                            onChange={(e) => setInquire({ ...inquire, firstName: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Last Name'
                            required
                            fullWidth
                            value={inquire.lastName}
                            onChange={(e) => setInquire({ ...inquire, lastName: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Email'
                            required
                            fullWidth
                            value={inquire.email}
                            onChange={(e) => setInquire({ ...inquire, email: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Phone'
                            fullWidth
                            value={inquire.phone}
                            onChange={(e) => setInquire({ ...inquire, phone: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label='Message'
                            fullWidth
                            multiline
                            minRows={5}
                            value={inquire.message}
                            onChange={(e) => setInquire({ ...inquire, message: e.target.value })}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            !isRobot &&
                            <Button
                                style={styles.button}
                                onClick={contact}
                                disabled={!inquire.firstName ||
                                    !inquire.lastName ||
                                    !inquire.email ||
                                    !inquire.message}
                            >
                                Contact
                            </Button>
                        }
                    </Grid>
                </Grid>
            }
            <Dialog
                open={result !== undefined}
                onClose={() => setResult(undefined)}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    {
                        result?.success
                            ? 'Inquiry Sent'
                            : 'Unable to Send'
                    }
                </DialogTitle>
                <DialogContent>
                    {result?.message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResult(undefined)} color="primary" variant='contained'>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}