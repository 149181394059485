import { Slide } from "@mui/material";
import React from "react";
import Overlay from "./Overlay";

export default function SlideImage() {
    const [showImage, setShowImage] = React.useState(-1);
    const [imageUrls, setImageUrls] = React.useState<string[]>([]);
    const [loading, setIsLoading] = React.useState(true);

    const images = ['./mainImages/forest.jpg', './mainImages/river.jpg', './mainImages/stream.jpg', './mainImages/lake.jpg', './mainImages/lake2.jpg', './mainImages/soloHike.jpg'];

    React.useEffect(() => {
        const promsies = images.map(img => fetch(img).then(r => r.blob().then((b: Blob) => URL.createObjectURL(b))));

        promsies.forEach(p => {
            p.then(r => {
                setImageUrls(prev => [...prev, r])
                setIsLoading(false);
            })
        })

        const interval = setInterval(() => {
            setShowImage(prevTime => (prevTime + 1) % images.length);
        }, 10000)

        return () => { clearInterval(interval); }
    }, [])


    return (
        <>
            {
                loading &&
                <Overlay />
            }
            <img className="fullPageImage" src={'./mainImages/stream.jpg'} />
            {
                imageUrls.map((image: string, index: number) =>
                    <Slide direction={showImage === index ? 'left' : 'right'} timeout={1000} in={showImage === index} mountOnEnter unmountOnExit>
                        <img className="fullPageImage" src={image} />
                    </Slide>
                )
            }
        </>
    )
}