import Product from "./Product";
import { Grid } from "@mui/material";
import Whys from "./Whys";
import SlideImage from "../SlideImage";

export default function HomeView() {
    const styles = {
        main: {
            height: '60vh'
        },
        why: {
            padding: '20px'
        },
        arrow: {
            paddingLeft: '20px',
        }
    }

    return (
        <article>
            <SlideImage />
            <Grid container style={styles.main}>
                <Grid item xs={12} md={6} className="flexRowCenter">
                    <Product />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Whys />
                </Grid>
            </Grid>
        </article>
    )
}